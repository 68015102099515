import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/WorkplaceLayout.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        meta: {
          title: '维修服务',
          navBarTitle: false,
          noticeBar: false
        },
        component: () => import('@/views/index/index.vue')
      },
      {
        path: '/choose_product',
        name: 'ChooseProduct',
        meta: {
          title: '选择商品'
        },
        component: () => import('@/views/chooseProductWay/index.vue')
      },
      {
        path: '/warranty_check/:id',
        name: 'WarrantyCheck',
        meta: {
          title: '保修检测'
        },
        component: () => import('@/views/warrantyCheck/index.vue')
      },
      {
        path: '/application_form/:id',
        name: 'ApplicationForm',
        meta: {
          title: '维修申请'
        },
        component: () => import('@/views/applicationForm/index.vue')
      },
      {
        path: '/fill_express_info',
        name: 'FillExpressInfo',
        meta: {
          title: '填写快递信息'
        },
        component: () => import('@/views/fillExpressInfo/index.vue')
      },
      {
        path: '/repair_list',
        name: 'RepairList',
        meta: {
          title: '我的报修单'
        },
        component: () => import('@/views/myRepairOrder/repairList/index.vue')
      },
      {
        path: '/repair_detail/:id',
        name: 'RepairDetail',
        meta: {
          title: '报修单详情',
          navBarTitle: false
        },
        component: () => import('@/views/myRepairOrder/repairDetail/index.vue')
      },
      {
        path: '/apply_invoice/:id',
        name: 'ApplyInvoice',
        meta: {
          title: '申请开票'
        },
        component: () => import('@/views/invoice/applyInvoice/index.vue')
      },
      {
        path: '/invoice_result/:id',
        name: 'InvoiceResult',
        meta: {
          title: '完成'
        },
        component: () => import('@/views/invoice/invoiceResult/index.vue')
      },
      {
        path: '/payment/:id',
        name: 'Payment',
        meta: {
          title: '支付'
        },
        component: () => import('@/views/payment/index.vue')
      },
      {
        path: '/pay_result',
        name: 'PayResult',
        meta: {
          title: '支付结果'
        },
        component: () => import('@/views/payResult/index.vue')
      },
      {
        path: '/repair_result',
        name: 'RepairResult',
        meta: {
          title: '维修结果'
        },
        component: () => import('@/views/repairResult/index.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      navBarTitle: false
    },
    component: () => import('@/views/notFound/index.vue')
  }
]

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

// push
VueRouter.prototype.push = function (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

//replace
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
