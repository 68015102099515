/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SystemConfigDetailReply } from '../models';
// @ts-ignore
import { SystemConfigPageReply } from '../models';
/**
 * SystemConfigApi - axios parameter creator
 * @export
 */
export const SystemConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 系统配置列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiSystemConfigEffectGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/SystemConfig/Effect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 系统配置列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiSystemConfigGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/SystemConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 系统配置详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiSystemConfigIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiSystemConfigIdGet', 'id', id)
            const localVarPath = `/custapi/SystemConfig/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemConfigApi - functional programming interface
 * @export
 */
export const SystemConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 系统配置列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiSystemConfigEffectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemConfigPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiSystemConfigEffectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 系统配置列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiSystemConfigGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemConfigPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiSystemConfigGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 系统配置详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiSystemConfigIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemConfigDetailReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiSystemConfigIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemConfigApi - factory interface
 * @export
 */
export const SystemConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary 系统配置列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiSystemConfigEffectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<SystemConfigPageReply>> {
            return localVarFp.custapiSystemConfigEffectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 系统配置列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiSystemConfigGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<SystemConfigPageReply>> {
            return localVarFp.custapiSystemConfigGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 系统配置详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiSystemConfigIdGet(id: number, options?: any): AxiosPromise<SystemConfigDetailReply> {
            return localVarFp.custapiSystemConfigIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemConfigApi - object-oriented interface
 * @export
 * @class SystemConfigApi
 * @extends {BaseAPI}
 */
export class SystemConfigApi extends BaseAPI {
    /**
     * 
     * @summary 系统配置列表
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigApi
     */
    public custapiSystemConfigEffectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return SystemConfigApiFp(this.configuration).custapiSystemConfigEffectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 系统配置列表
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigApi
     */
    public custapiSystemConfigGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return SystemConfigApiFp(this.configuration).custapiSystemConfigGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 系统配置详情
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigApi
     */
    public custapiSystemConfigIdGet(id: number, options?: AxiosRequestConfig) {
        return SystemConfigApiFp(this.configuration).custapiSystemConfigIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}
