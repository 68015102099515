import Util from '@/utils/util'
import { Toast } from 'vant'
const statusMsgMap = {
  400: '请求出错，请联系客服',
  401: '登录失效，请退出维修系统重新进入',
  403: '无权限操作，请联系客服',
  404: '未找到资源',
  405: '非法操作，请联系客服',
  412: '数据已更新，请刷新',
  422: '参数错误，请检查输入',
  500: '服务器错误，请联系客服',
  504: '网络不稳定，请稍候再试'
}

const notice = msg => {
  Toast({
    message: msg
  })
}

const getParameterErrorMsg = error => {
  const msgList = []
  Object.keys(error).forEach(key => {
    const errorItem = error[key]
    if (errorItem?.length) {
      errorItem.forEach(item => {
        msgList.push(item)
      })
    }
  })
  return msgList
}

const noticeDebounce = Util.debounce(notice, 1000)

/**
 * 请求异常处理，后端没给相应提示就用本地配置的提示
 */
export const errorHandler = error => {
  // 需要隐藏msg时，添加请求头Ignore-Message为true
  if (error.response && !error?.config?.headers['Ignore-Message'] && error.response.status !== 429) {
    const { status, data = {} } = error.response
    let msg = data.title || statusMsgMap[status]
    if (status === 422 && data.errors) {
      const msgList = getParameterErrorMsg(data.errors)
      if (msgList.length) msg = msgList[0]
    }
    if (msg) noticeDebounce(msg)
  }
  // 无论有没有response网络错误都要处理（跨域的时候会有response）
  if (error?.title == 'Network Error' || (error?.message?.startsWith('timeout') && error?.isAxiosError)) {
    noticeDebounce('网络连接不可用，请稍候重试')
  }
  console.error('----网络请求错误', error)
  return Promise.reject(error)
}
