/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustRepairRequestOrderDetailReply } from '../models';
// @ts-ignore
import { Operation } from '../models';
/**
 * RepairRequestOrderApi - axios parameter creator
 * @export
 */
export const RepairRequestOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 删除报修单
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairRequestOrderIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairRequestOrderIdDelete', 'id', id)
            const localVarPath = `/custapi/RepairRequestOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 报修单详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairRequestOrderIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairRequestOrderIdGet', 'id', id)
            const localVarPath = `/custapi/RepairRequestOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 状态切换
         * @param {number} id 
         * @param {string} [transition] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairRequestOrderIdStatePatch: async (id: number, transition?: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairRequestOrderIdStatePatch', 'id', id)
            const localVarPath = `/custapi/RepairRequestOrder/{id}/State`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (transition !== undefined) {
                localVarQueryParameter['transition'] = transition;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairRequestOrderApi - functional programming interface
 * @export
 */
export const RepairRequestOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairRequestOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 删除报修单
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairRequestOrderIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairRequestOrderIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 报修单详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairRequestOrderIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustRepairRequestOrderDetailReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairRequestOrderIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 状态切换
         * @param {number} id 
         * @param {string} [transition] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairRequestOrderIdStatePatch(id: number, transition?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairRequestOrderIdStatePatch(id, transition, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepairRequestOrderApi - factory interface
 * @export
 */
export const RepairRequestOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairRequestOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary 删除报修单
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairRequestOrderIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.custapiRepairRequestOrderIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 报修单详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairRequestOrderIdGet(id: number, options?: any): AxiosPromise<CustRepairRequestOrderDetailReply> {
            return localVarFp.custapiRepairRequestOrderIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 状态切换
         * @param {number} id 
         * @param {string} [transition] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairRequestOrderIdStatePatch(id: number, transition?: string, operation?: Array<Operation>, options?: any): AxiosPromise<void> {
            return localVarFp.custapiRepairRequestOrderIdStatePatch(id, transition, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepairRequestOrderApi - object-oriented interface
 * @export
 * @class RepairRequestOrderApi
 * @extends {BaseAPI}
 */
export class RepairRequestOrderApi extends BaseAPI {
    /**
     * 
     * @summary 删除报修单
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairRequestOrderApi
     */
    public custapiRepairRequestOrderIdDelete(id: number, options?: AxiosRequestConfig) {
        return RepairRequestOrderApiFp(this.configuration).custapiRepairRequestOrderIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 报修单详情
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairRequestOrderApi
     */
    public custapiRepairRequestOrderIdGet(id: number, options?: AxiosRequestConfig) {
        return RepairRequestOrderApiFp(this.configuration).custapiRepairRequestOrderIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 状态切换
     * @param {number} id 
     * @param {string} [transition] 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairRequestOrderApi
     */
    public custapiRepairRequestOrderIdStatePatch(id: number, transition?: string, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return RepairRequestOrderApiFp(this.configuration).custapiRepairRequestOrderIdStatePatch(id, transition, operation, options).then((request) => request(this.axios, this.basePath));
    }
}
