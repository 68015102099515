import api from '@/utils/api'

export const repair = {
  state: {
    listData: [],
    scrollTop: 0,
    isAllowApply: false
  },
  mutations: {
    SET_LIST_DATA: (state, list) => {
      state.listData = list
    },
    SET_SCROLL_TOP: (state, scrollTop) => {
      state.scrollTop = scrollTop
    },
    UPDATE_REPAIR_DATA: (state, data) => {
      const result = state.listData.find(item => item.id === data.id)
      if (result) {
        result.statusName = data.statusName
        result.status = data.status
        result.transitions = data.transitions
        result.repairRequestOrderId = data.repairRequestOrderId
        if (data.repairRequestOrderId) {
          const repairPlanInfo = data.relateRepairRequestOrder?.relateRepairPlanReplies?.[0] || {}
          result.relateRepairRequestOrder = {
            ...data.relateRepairRequestOrder,
            hasRepairPlan: data.relateRepairRequestOrder?.relateRepairPlanReplies?.length || 0,
            ...repairPlanInfo
          }
        }
      }
    },
    CLEAR_REPAIR_DATA: state => {
      state.listData = []
      state.scrollTop = 0
    },
    SET_ALLOW_APPLY: (state, isAllowApply) => {
      state.isAllowApply = isAllowApply
    }
  },
  actions: {
    async updateRepairData({ commit }, id) {
      if (!id) return
      const { data } = await api.custapiRepairPreAuditOrderGet(1, 1, 'id__eq=' + id)
      const res = data[0]
      commit('UPDATE_REPAIR_DATA', res)
    }
  }
}
