import Util from '@/utils/util'
import store from '@/store'
import AppProtocol from '@/utils/appProtocol'
import cookieJson from '../../cookie.json'
import Cookie from '@/utils/cookie'

export async function initAuth() {
  const isInApp = Util.isInApp()
  const domain = location.hostname
  if (isInApp) {
    console.debug('------app环境中------')
    //app环境中，获取app头部信息后登录
    const headerInfo = await AppProtocol.getHeadInfo()
    window.headerInfo = JSON.stringify(headerInfo)
    let cookie_domain = domain
    if (window.location.host.indexOf('babybus') > -1) {
      cookie_domain = '.babybus.com'
    }
    store.commit(
      'SET_HEADER_INFO',
      Object.assign({}, headerInfo, {
        domain: cookie_domain
      })
    )

    const isLogin = await AppProtocol.isLogin()
    store.commit('SET_LOGIN_STATE', isLogin)

    // 获取用户信息
    // if (isLogin) {
    //   await AppProtocol.getHeadInfo()
    //     .then(res => {
    //       store.commit('SET_HEADER_INFO', res.data)
    //     })
    //     .catch(err => console.warn(err))
    // }
  } else if (Util.isInMiniProgram()) {
    const isLogin = Util.getUrlParameterByName('isLogin', location.search)
    let EnCryptType = Util.getUrlParameterByName('EncryptType', location.search)
    let ClientHeaderInfo = Util.getUrlParameterByName('ClientHeaderInfo', location.search)
    let ProductID = Util.getUrlParameterByName('ProductID', location.search)

    // 没有携带参数的情况尝试从cookie中获取
    if (!EnCryptType) {
      EnCryptType = Cookie.get('Client_EncryptType')
    }
    if (!ClientHeaderInfo) {
      ClientHeaderInfo = Cookie.get('ClientHeaderInfo')
    }
    if (!ProductID) {
      ProductID = Cookie.get('Client_ProductID')
    }

    store.commit('SET_LOGIN_STATE', isLogin == 1)
    const headerInfo = {
      enCryptType: EnCryptType,
      headInfo: ClientHeaderInfo,
      productId: ProductID
    }
    store.commit(
      'SET_HEADER_INFO',
      Object.assign({}, headerInfo, {
        domain: window.location.host.indexOf('babybus') > -1 ? '.babybus.com' : location.hostname
      })
    )
  } else if (process.env.VUE_APP_MODE === 'development') {
    console.debug('------开发环境（本地环境）下，在非app环境中模拟登录------')

    const data = cookieJson[process.env.VUE_APP_MODE].reduce((pre, current) => {
      return {
        ...pre,
        [current.name]: decodeURIComponent(current.value)
      }
    }, {})

    const headerInfo = {
      enCryptType: data.Client_EncryptType,
      headInfo: data.ClientHeaderInfo,
      productId: data.Client_ProductID
    }

    store.commit(
      'SET_HEADER_INFO',
      Object.assign({}, headerInfo, {
        domain: window.location.host.indexOf('babybus') > -1 ? '.babybus.com' : location.hostname
      })
    )
    store.commit('SET_LOGIN_STATE', true)
  }

  if (!store.state.user.isLogin) {
    // 未登录状态跳转登录 （防一手）
    if (isInApp) {
      AppProtocol.toLogin()
    }
    // else if (Util.isInMiniProgram()) {
    //   // eslint-disable-next-line
    //   wx.miniProgram.navigateTo({
    //     url: `/pages/user-login/index/index?from=webview`
    //   })
    // }
  }
}
